import React from "react"
import { useEffect, useState } from "react"
import ModalC from './modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'
import 'swiper/css';

const Plans = () => {
    const [flats, setFlats] = useState([])
    const [swiper, setSwiper] = useState(null)
    const [showPrev, setShowPrev] = useState(true)
    const [type, setType] = useState("all")
    const [isOpen, setIsOpen] = useState(null)
    const [flatPopup, setFlatPopup] = useState(null)

    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/flats.php", {})
            .then(res => res.json())
            .then((result) => {

                setFlats(result)
                console.log(flats)
            })
    }, [])

    const getTitle = (rooms) => {
        switch (rooms) {
            case 0:
                return "Студия"
            case 1:
                return "1-комнатная"
            case 2:
                return "2-комнатная"
            case 3:
                return "3-комнатная"
        }
    }




    const setShowArrow = () => {
        if (swiper.realIndex !== 0) {
            setShowPrev(true)
        } else {
            setShowPrev(false)
        }
    }

    return (
        <React.Fragment>
            <section id="flat" className="flat">
                <div className="flat_inner">
                    <h2 className="caption__h2 caption__h2--brown"><span className="aos-init aos-animate">ПЛАНИРОВКИ КВАРТИР</span></h2>
                    <div className="flat_nav">
                        <div onClick={() => { setType("all"); }} className={type == "all" ? "act" : ""}>ВСЕ</div>
                        <div onClick={() => { setType(1); }} className={type == 1 ? "act" : ""}>1-КОМ</div>
                        <div onClick={() => { setType(2); }} className={type == 2 ? "act" : ""}>2-КОМ</div>
                        <div onClick={() => { setType(3); }} className={type == 3 ? "act" : ""}>3-КОМ</div>
                    </div>
                    <Swiper
                        className='flat_slider'
                        modules={Navigation}
                        speed={400}
                        slidesPerView={1}
                        spaceBetween={60}
                        loop={false}
                        autoHeight={true}
                        onInit={(swiper)=>{setSwiper(swiper)}}
                        breakpoints={{
                            580: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            860: {
                                slidesPerView: 3,
                                spaceBetween: 32,
                            },
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 32,
                            },
                        }}
                    >
                        {flats.filter((el) => el.rooms == type || type == "all").map((flat, index) => {
                            return <SwiperSlide>
                            <div className="flat_slide" onClick={(e)=>{e.preventDefault(); setIsOpen(true); setFlatPopup(flat)}}>
                                <div className="fs_img"><img src={process.env.REACT_APP_PLANS_URL + flat.photo} /></div>
                                <div className="fs_name">{getTitle(flat.rooms)} КВАРТИРА</div>
                                <div className="fs_area">
                                    <span>ОБЩАЯ <br />ПЛЛОЩАДЬ</span>
                                    <b>{flat.total_area}<sup>м²</sup></b>
                                </div>
                                <div className="fs_btn" onClick={(e)=>{e.preventDefault(); setIsOpen(true); setFlatPopup(flat)}} >Узнать стоимость</div>
                            </div>
                        </SwiperSlide>
                        })}
                        
                        <div className="swiper-button-prev" style={{backgroundColor:"transparent"}} onClick={()=>{swiper.slidePrev(); setShowArrow()}}>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="40" transform="matrix(-1 0 0 1 80 0)" fill="#E0833C" fill-opacity="0.8" />
                                <path d="M29.5858 41.4142C28.8047 40.6332 28.8047 39.3668 29.5858 38.5858L42.3137 25.8579C43.0948 25.0768 44.3611 25.0768 45.1421 25.8579C45.9232 26.6389 45.9232 27.9052 45.1421 28.6863L33.8284 40L45.1421 51.3137C45.9232 52.0948 45.9232 53.3611 45.1421 54.1421C44.3611 54.9232 43.0948 54.9232 42.3137 54.1421L29.5858 41.4142ZM35 42H31V38H35V42Z" fill="white" />
                            </svg>
                        </div>
                        <div className="swiper-button-next" style={{backgroundColor:"transparent",}} onClick={()=>{swiper.slideNext(); setShowArrow()}}>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="40" fill="#E0833C" fill-opacity="0.8" />
                                <path d="M50.4142 41.4142C51.1953 40.6332 51.1953 39.3668 50.4142 38.5858L37.6863 25.8579C36.9052 25.0768 35.6389 25.0768 34.8579 25.8579C34.0768 26.6389 34.0768 27.9052 34.8579 28.6863L46.1716 40L34.8579 51.3137C34.0768 52.0948 34.0768 53.3611 34.8579 54.1421C35.6389 54.9232 36.9052 54.9232 37.6863 54.1421L50.4142 41.4142ZM45 42H49V38H45V42Z" fill="white" />
                            </svg>
                        </div>


                    </Swiper>
                </div>
            </section>
            {isOpen ? <ModalC
                title={"Узнайте стоимость квартиры на сегодня"}
                position={window.scrollY}
                flat={flatPopup}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_name",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_phone",
                    },
                ]}
                hidden={"Узнать стоимость"}
                btnTitle={"Узнать стоимость"}
                celtype={"getFlatCost"}
                template={1}
                callback={() => { console.log("success") }}
                personal={true}
                close={() => { setIsOpen(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}

export default Plans