import { useState } from "react"

const SelectInput = ({field}) => {
    const [selectedOption, setSelectedOption] = useState(null)

    return (
        <div className="form__row">
            <div className="form__input-wrap">
                <select name="contribution" id="af_contribution" className="form__select" onChange={(e)=>{setSelectedOption(e.target.value)}}>
                    <option value="">{field.placeholder}</option>
                    {field.options && field.options.map((option)=>{
                        return <option value={option}>{option}</option>
                    })}
                </select>
            </div>
            <input type="hidden" className="dop-info" data={field.placeholder} value={selectedOption} />
        </div>
    )
}

export default SelectInput