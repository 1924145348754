export const Footer = () => {
    return (
        <footer className="footer active">
            <div className="container">
                <div className="f_politic">
                    Продолжая использовать наш сайт и отправляя любую форму на сайте, Вы даете согласие на обработку файлов <a target="_blank" href="/docs/Politika-konf.doc">Cookies</a> и других пользовательских данных, в соответствии с <a target="_blank" href="/docs/Politika-konf_futurist.doc">политикой конфиденциальности</a>, а также соглашаетесь на получение
                    рассылки на электронную почту, указанную в заявке. Запретить обработку <a target="_blank" href="/docs/Politika-konf.doc">Cookies</a> можно в настройках Вашего браузера.
                </div>
                <div className="footer-logo">
                    <img alt="" src="/img/logo.svg" className="lazy footer-logo__img" />
                </div>
            </div>
        </footer>
    )
}

export default Footer
