import { BlocksContext } from "./context/blocksContext"
import { useBlocks } from "./hooks/blocks.hook"
import { Loader } from './components/loader';
import "./css/style.css";
import "./css/slick.css";
import "./css/swiper.css";

function App() {
  const { blocks, setBlocks, menuClick, setMenuClick } = useBlocks()
  return (
      <BlocksContext.Provider value={{ blocks, setBlocks, menuClick, setMenuClick }}>
        <Loader />
      </BlocksContext.Provider>
  );
}

export default App;