import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { BlocksContext } from "../context/blocksContext";
import ModalC from './modal'

export const CountDown = (props) => {
    const [isOpen, setModalState] = useState(false)
    const blocks = useContext(BlocksContext)
    const [time, setTime] = useState(false)
    const endDate = Date.parse("01 Jul 2024 00:00:00 GMT+0300")
    const countTime = () => {
        let now = new Date()
        if (endDate < now) {
            setTime(false)
            return false
        }
        var diff = Math.abs(endDate - now);
        console.log(dhm(diff))
        setTime(dhm(diff))
        return true
    }

    const dhm = (t) => {
        var cd = 24 * 60 * 60 * 1000,
            ch = 60 * 60 * 1000,
            d = Math.floor(t / cd),
            h = Math.floor((t - d * cd) / ch),
            m = Math.round((t - d * cd - h * ch) / 60000),
            pad = function (n) { return n < 10 ? '0' + n : n; };
        if (m === 60) {
            h++;
            m = 0;
        }
        if (h === 24) {
            d++;
            h = 0;
        }

        return {
            days: d,
            hours: pad(h),
            minutes: pad(m),
        };
    }

    useEffect(() => {
        countTime()
        let interval = setInterval(() => {
            if (!countTime()) {
                clearInterval(interval);
            }
        }, 1000)
    }, [])

    const showPopup = (e) => {
        e.preventDefault()
        console.log(blocks.blocks)
        if (blocks.blocks < 4) {
            blocks.setBlocks(19)
            let target = e.currentTarget
            setTimeout(() => {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.' + target.getAttribute('data')).style.display = "block"
            }, 1800)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
            console.log(e.currentTarget.getAttribute('data'))
            document.querySelector('.' + e.currentTarget.getAttribute('data')).style.display = "block"
        }
    }

    return (
        <React.Fragment>
            {
                true ? <div className='header_timer_main'>
                    <div className='ht__title'>
                        УСПЕЙТЕ КУПИТЬ <br />КВАРТИРУ
                        <span>С ГОСПОДДЕРЖКОЙ</span>
                    </div>
                    <ul className='ht__list'>
                        <li>
                            Платежи по ипотеке, <br />если купить до 1 июля:<br />
                            <span>
                                40 321 ₽ / мес, ставка 7,1%
                            </span>
                        </li>
                        <li>
                            Платежи по ипотеке, <br />если купить после 1 июля:<br />
                            <span>
                                74 473 ₽ / мес, ставка 14,5%
                            </span>
                        </li>
                    </ul>
                    <div className='header_timer_inner'>
                        <div className='ht__name'>
                            ДО ОКОНЧАНИЯ ЛЬГОТНОЙ <br />ИПОТЕКИ ОСТАЛОСЬ
                        </div>
                        <ul className='header_timer'>
                            <li>
                                <div className='ht__num'>{time.days}</div>
                                <span>ДНЕЙ</span>
                            </li>
                            <li>
                                <div className='ht__num'>{time.hours}</div>
                                <span>ЧАСОВ</span>
                            </li>
                            <li>
                                <div className='ht__num'>{time.minutes}</div>
                                <span>МИНУТ</span>
                            </li>
                        </ul>
                        <div className='ht__btn' style={{ cursor: "pointer" }} onClick={() => { console.log(isOpen); props.setIsOpenCountDown(true) }}>
                            <span >Зафиксировать ставку</span>
                        </div>
                    </div>
                </div>

                    : <></>
            }

        </React.Fragment>
    )
}

export default CountDown